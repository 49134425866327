import './Component.css';
import './Navigation.css';
import React, { Component } from "react";

const ABOUT = 'About';
const COMMENT = 'Comment';
const CONTACT = 'Contact';
const PORTFOLIO = 'Portfolio';

const PAGES = {
  ABOUT: ABOUT,
  COMMENT: COMMENT,
  CONTACT: CONTACT,
  PORTFOLIO: PORTFOLIO,
}

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  };

  handleClick(e) {
    this.props.choiceFunction(e.target.name);
  }

  render() {
    return (
      <main>
        <div class="nav-bar-container">
          <div class="hamburger-menu">
            <input type="checkbox" />
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="nav-bar">
            <button className={ this.props.currentPage == PAGES.ABOUT ? "nav-button nav-button-selected" : "nav-button "} name={PAGES.ABOUT} onClick={this.handleClick}>
              Home
            </button>
            <button className={ this.props.currentPage == PAGES.COMMENT ? "nav-button nav-button-selected" : "nav-button "} name={PAGES.COMMENT} onClick={this.handleClick}>
              Comment
            </button>{/*
            <button className={ this.props.currentPage == PAGES.PORTFOLIO ? "nav-button nav-button-selected" : "nav-button "} name={PAGES.PORTFOLIO} onClick={this.handleClick}>
              My Work
            </button>*/}
            <button className={ this.props.currentPage == PAGES.CONTACT ? "nav-button nav-button-selected" : "nav-button "} name={PAGES.CONTACT} onClick={this.handleClick}>
              Contact
            </button>
          </div>
        </div>
      </main>
    );
  }
}

export default Navigation;

export {ABOUT};
export {COMMENT};
export {CONTACT};
export {PAGES};
