import './Component.css';
import './About.css';
import React, { Component } from "react";


import profile from '../images/Profile.jpg';
import resume from "../documents/Ben Fridman Resume.pdf"

class About extends Component {
  constructor(props) {
    super(props);
    let scrollStart;
    this.state = {
      chunk: 0,
    };

    this.renderChunk = this.renderChunk.bind(this);
    this.handleClick = this.handleClick.bind(this);
  };



  handleClick(event) {
    let inc = 0;
    if (event.target.id === "right") {
      inc = 1;
    }
    else if (event.target.id === "left") {
      inc = -1;
    }
    else {
      this.setState({
        chunk: Number(event.target.id)
      });
      return
    }
    this.setState({
      chunk: this.state.chunk + inc
    });
  };

  renderLeft() {
    let ret;
    if (this.state.chunk > 0) {
      ret = (
          <div class="d-flex align-items-center justify-content-center panel left-panel">
            <div class="hover-highlight" onClick={(e) => this.handleClick(e)} id="left">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill=" #00243d" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
              </svg>
            </div>
          </div>
      )
      } else {
        ret = (
            <div class=" d-flex align-items-center left-panel">
            </div>
            )
      }
    return ret
  }

  renderRight() {
    let ret;
    if (this.state.chunk < 2) {
      ret = (
          <div class="d-flex align-items-center justify-content-center panel right-panel">
            <div class="hover-highlight" onClick={(e) => this.handleClick(e)} id="right">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill=" #00243d" class="bi bi-arrow-right" viewBox="0 0 16 16" >
                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
              </svg>
            </div>
          </div>
      )
    } else {
        ret = (
            <div class=" d-flex align-items-center right-panel">
            </div>
            )
    }
    return ret
  }

  renderImage() {

    let ret;
    switch (this.state.chunk) {
      case 0: 
        ret = (
            <div class="ben-binoculors-image about-image fade-in" key="binoculars-image">
            </div>
          )
        break;
      case 1:
        ret = (
            <div class="ben-cartoon-computer-image about-image fade-in" key="computer-image">
            </div>
          )
        break;
      case 2:
        ret = (
            <div class="ben-mind-image about-image fade-in" key="mind-image">
            </div>
          )
        break;
      }
        return ret;
  };

  renderDots() {
    let ret;

    ret = (
      <div>
        <a onClick={(e) => this.handleClick(e)} id='0'>
          <svg width="24" height="24">
            <circle cx="12" cy="12" className={ this.state.chunk == 0 ? "about-dot-selected" : ""} r="4" />
          </svg>
        </a>
        <a onClick={(e) => this.handleClick(e)} id='1'>
          <svg width="24" height="24">
            <circle cx="12" cy="12" className={ this.state.chunk == 1 ? "about-dot-selected" : ""} r="4" />
          </svg>
        </a>
        <a onClick={(e) => this.handleClick(e)} id='2'>
          <svg width="24" height="24">
            <circle cx="12" cy="12" className={ this.state.chunk == 2 ? "about-dot-selected" : ""} r="4" />
          </svg>
        </a>
      </div>
    )

      return ret
  }
  

  renderChunk() {
    let ret;
    switch (this.state.chunk) {
      case 0: 
        ret = (
          <div class="d-flex flex-wrap align-items-center justify-content-center text-bubble fade-in" key="chunk0">
            <div class="">
              <div class="py-3 pl-3 pr-0 justify-content-start display-5">
              Hey there, I'm Ben
              </div>
              <div class="p-1 px-3">
              My name is Benjamin Fridman. I'm a professional software developer based in Toronto.
              </div>
              <div class="p-1 px-3">
              My passion for technology has driven my career in software development. My passion for people has driven my focus on mentorship and collaboration.
              </div>
            </div>
          </div>
        )
        break;
      case 1: 
        ret = (
            <div class="col-md-9 pl-4 d-flex flex-wrap align-items-center justify-content-center text-bubble fade-in " key="chunk1">
              <div class="">
                <div class="py-3 pl-3 pr-0 justify-content-start display-5">
                  My work
                </div>
                <div class="p-1 px-3">
                  I develop web applications. As a full-stack developer I have years of experience using my talent to craft functional interfaces, engineering elegant APIs, and mentoring colleagues toward job-ready skills.
                </div>
                <div class="p-1 px-3">
                  I design systems from interface to database, and mentor from Hello World to the real world.
                </div>
                
              </div>
            </div>
        )
        break;
      case 2: 
        ret = (
            <div class="col-md-9 pl-4 d-flex flex-wrap align-items-center justify-content-center text-bubble fade-in" key="chunk2">
              <div class="">
                <div class="py-3 pl-3 pr-0 justify-content-start display-5">
                  My method
                </div>
                <div class="p-1 px-3">
                  I believe that an effective programmer is not just a technical specialist, but a leader, a teammate, a user, and a mentor.
                </div>
                <div class="p-1 px-3">
                  Collaboration and understanding is key, and that's why I approach every job with a focus on both the people I'm working for and with.
                </div>
                <div class="p-1 px-3">
                  I don't just build software, I build relationships.
                </div>
              </div>
            </div>
        )
        break;
    }
    return ret
        
  };

  render() {
    return (
      <main className="about-page">
        
        <div class="about-buttons-container">
          <a href="https://www.linkedin.com/in/benjamin-fridman-2a605066/" class="about-image-anchor" target="_blank">
            <div class="linked-in-button">
              <div class="about-button-cover">
              </div>
            </div>
          </a>
          <a href="https://www.github.com/Fridman/" class="about-image-anchor" target="_blank">
            <div class="github-button">
              <div class="about-button-cover">
              </div>
            </div>
          </a>
          <a href={resume} class="about-image-anchor" target="_blank">
            <div class="resume-button">
              <div class="about-button-cover">
              </div>
            </div>
          </a>
          
          {/*<div class="ben-button-container">
            <div class="linkedin-button">
            </div>
            <div class="github-button">
            </div>
          </div>*/}
        </div>
        <div class="about-description-container">
          
          <div class="about-description-widget" key="chunk0">
            {this.renderLeft()}
            {this.renderChunk()}
            {this.renderRight()}

          </div>
          <div class="dots-container">
            { this.renderDots() }
          </div>
        </div>
        <div class="about-image-container">
          {this.renderImage()}
          {/*<div class="ben-button-container">
            <div class="linkedin-button">
            </div>
            <div class="github-button">
            </div>
          </div>*/}
        </div>
        
      </main>
    );
  }
}

export default About;