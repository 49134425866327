import logo from './logo.svg';
import './components/Component.css';
import './App.css';
import React, { Component } from "react";

import About from './components/About';
import CommentBox from './components/CommentBox';
import ContactBox from './components/ContactBox';
import Navigation from './components/Navigation';

import { ABOUT, COMMENT, CONTACT, PAGES } from './components/Navigation'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedComponent: PAGES.ABOUT,
      animate_rotation: false,
      animate_page_transition: false,
    }
    let scrollStart;
    this.About = React.createRef();
    this.leftSwipe = React.createRef();
    this.rightSwipe = React.createRef();
  };

  componentDidMount(){
    document.title = "Ben Fridman - Toronto Software Developer"
  }

  choosePage = (page) => {
    this.setState({
      displayedComponent: page,
    });
    this.setState({animate_page_transition: true})
  }

  startSwipe(e) {
    this.scrollStart = {'x': e.touches[0].clientX, 'y': e.touches[0].clientY}
  }

  midSwipe(e) {
    const currentScroll = {'x': e.touches[0].clientX, 'y': e.touches[0].clientY}
    const swipeDistanceX = this.scrollStart.x - currentScroll.x;

    if (swipeDistanceX < -30) { //left
      this.rightSwipe.current.style.opacity = Math.abs(swipeDistanceX) / 100;

      this.leftSwipe.current.style.opacity = 0;

    } else if (swipeDistanceX > 30) { //right
      this.rightSwipe.current.style.opacity = 0;

      this.leftSwipe.current.style.opacity = Math.abs(swipeDistanceX) / 100;

    } else {



      this.rightSwipe.current.style.opacity = 0;
      this.leftSwipe.current.style.opacity = 0;
    }
  }

  handleSwipe(e) {
    // define the minimum distance to trigger the action
    this.rightSwipe.current.style.opacity = 0;
    this.leftSwipe.current.style.opacity = 0;
    const minDistance = 100;
    const scrollEnd = {'x': e.changedTouches[0].clientX, 'y': e.changedTouches[0].clientY}
    // get the distance the user swiped
    const swipeDistanceX = this.scrollStart.x - scrollEnd.x;
    const swipeDistanceY = this.scrollStart.y - scrollEnd.y;
    this.scrollStart = {}
    if (Math.abs(swipeDistanceX) > Math.abs(swipeDistanceY)) {
      if (this.state.displayedComponent == PAGES.ABOUT) {
        if (swipeDistanceX < minDistance * -1 && this.About.current.state.chunk > 0) {
          this.About.current.handleClick({"target" : {"id": "left"}});
        } else if (swipeDistanceX > minDistance && this.About.current.state.chunk < 2) {
          this.About.current.handleClick({"target" : {"id": "right"}});
        } else if (swipeDistanceX > minDistance) {
          this.choosePage(PAGES.COMMENT)
        }

      } else if (this.state.displayedComponent == PAGES.COMMENT) {
        if (swipeDistanceX < minDistance * -1) {
          this.choosePage(PAGES.ABOUT)
          this.About.current.handleClick({"target" : {"id": "2"}});
        } else if (swipeDistanceX > minDistance) {
          this.choosePage(PAGES.CONTACT)
        }
      } else if (this.state.displayedComponent == PAGES.CONTACT) {
        if (swipeDistanceX < minDistance * -1) {
          this.choosePage(PAGES.COMMENT)
        }
      }
    }
  }

  render() {
    return (
      <main className="app-container" onTouchStart={(e) => this.startSwipe(e)} onTouchEnd={(e) => this.handleSwipe(e)} onTouchMove={(e) => this.midSwipe(e)}>
        <helmet>
          <title>Hey, thanks for stopping by!</title>
        </helmet>
        <div class="swipe-icon" ref={this.leftSwipe}>
          <svg xmlns="http://www.w3.org/2000/svg" width="144" height="96" fill=" #00243d" class="bi bi-arrow-right" viewBox="0 0 16 16" >
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
          </svg>
        </div>
        <div class="swipe-icon" ref={this.rightSwipe}>
          <svg xmlns="http://www.w3.org/2000/svg" width="144" height="96" fill=" #00243d" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
          </svg>
        </div>
        <Navigation choiceFunction={this.choosePage} currentPage={this.state.displayedComponent}/>
        <div
        className={ this.state.animate_page_transition == true ? "ben-container sliding-transition" : "ben-container "}
        onAnimationEnd={() => this.setState({ animate_page_transition: false })}
        >
          <div class={
            this.state.displayedComponent == PAGES.ABOUT ? 'fade-in-delay' : 'fade-out hidden' }
            onAnimationEnd={(event) => event.stopPropagation()}
          >
            <About class="position-relative" isHidden={
              this.state.displayedComponent == PAGES.ABOUT ? 'true' : 'false' }
            ref={this.About}/>
          </div>
          <div class={
            this.state.displayedComponent == PAGES.COMMENT ? 'fade-in-delay' : 'fade-out hidden' }
            onAnimationEnd={(event) => event.stopPropagation()}
          >
            <CommentBox class="position-relative" isHidden={
              this.state.displayedComponent == PAGES.COMMENT ? 'true' : 'false' }
            />
          </div>
          <div class={
            this.state.displayedComponent == PAGES.CONTACT ? 'fade-in-delay' : 'fade-out hidden' }
            onAnimationEnd={(event) => event.stopPropagation()}
          >
            <ContactBox class="position-relative" isHidden={
              this.state.displayedComponent == PAGES.CONTACT ? 'true' : 'false' }
            />
          </div>
          {/*<div class="site-bar footer">
          </div>*/}
        </div>
        
      </main>
    );
  }
}

export default App;
