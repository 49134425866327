import logo from '../logo.svg';
import './Component.css';
import './Contact.css';
import React, { Component } from "react";

const USERNAME_MAXIMUM = 32;
const BACKEND_URL = process.env.REACT_APP_BEFRIDMAN_URL;


class ContactBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      body: '',
      email: '',
      subject: 'contract',
      warning: '',
      success: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    if (event.target.name === 'user' && event.target.value.length > USERNAME_MAXIMUM) {
      return
    }
    this.setState(
      {...this.state, [event.target.name] : event.target.value }
      );

  }

  handleSubmit(event) {
    event.preventDefault()
    if (this.state.body === '' || this.state.user === '' || this.state.email === '') {
      this.setState({'success': '', 'warning': 'Please fill out all input fields before submitting.'});
      return;
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "user": this.state.user,
          "email": this.state.email,
          "subject": this.state.subject,
          "body": this.state.body
        })
    };
    fetch(BACKEND_URL + '/api/contact/', requestOptions)
    .then(response => {
      if (response.ok) {
        this.setState(
          {user : '', body: '', email: '', subject: '', warning: '', 'success': "Thank you so much for reaching out!" }
          );
      } else {
        this.setState({'success': '', 'warning': 'There was a server error.'});
      return;
      }
    });
  };

  render() {
    return (
      <main className="contact-page">
        <div class="contact-side contact-side-left">
        </div>
        <div class="contact-container">
          <span className="display-5">I'd love to hear from you!</span>
          {/*<div class="p-4 text-center">
            I'm always looking for new opportunities, whether that's a new job, working on exciting projects, engaging in my passion for teaching, or something else entirely.
            If you would like to contact me about an opportunity or simply to chat, feel free to fill out the form below.
          </div>*/}
          <div class="form-container">
            <form onSubmit={this.handleSubmit} className="d-flex justify-content-center">
              <label>
                <div className="form-group">
                  <label>Your Name</label>
                  <input className="form-control bg-transparent-25 vw-20" type="text" name="user" value={this.state.user} onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                  <label>Your Email</label>
                  <input className="form-control bg-transparent-25 vw-20" type="text" name="email" value={this.state.email} onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                  <label>Subject</label>
                 <select name="contact-subject"  className="form-control bg-transparent-25 vw-20" id="contact-subject" name="subject" value={this.state.subject} onChange={this.handleChange}>
                    <option value="contract">I have an contract opportunity</option>
                    <option value="job">I have an employment opportunity</option>
                    <option value="tutoring">I am interested in tutoring</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Your Message</label>
                  <textarea className="form-control bg-transparent-25 vw-20" type="text" name="body" value={this.state.body} onChange={this.handleChange} />
                </div>
                <input className="form-control" type="submit" value="Submit" class="btn btn-dark"/>
              </label>
            </form>
            </div>
          <div className="row justify-content-center align-items-start" style={{height: 5 + "vh"}}>
            <small className="text-danger">
              {this.state.warning}
            </small>
            <small className="text-success">
              {this.state.success}
            </small>
          </div> 
        </div>
        <div class="contact-side contact-side-right">
        </div>
      </main>
    );
  }
}

export default ContactBox;
