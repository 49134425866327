import logo from '../logo.svg';
import './Component.css';
import './Comment.css';
import React, { Component } from "react";

const COMMENT_MAXIMUM = 140;
const USERNAME_MAXIMUM = 32;
const BACKEND_URL = process.env.REACT_APP_BEFRIDMAN_URL;

class CommentBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentList: [],
      user: '',
      body: '',
      warning: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    if (event.target.name == 'user' && event.target.value.length > USERNAME_MAXIMUM) {
      return
    }
    this.setState(
      {...this.state, [event.target.name] : event.target.value }
      );
  }

  handleSubmit(event) {
    event.preventDefault()
    // POST request using fetch inside useEffect React hook
    if (this.state.body == '' || this.state.user == '') {
      this.setState({'warning': 'Please fill out both input fields before submitting.'});
      return;
    }
    if (this.state.body.length > COMMENT_MAXIMUM) {
      this.setState({'warning': 'Your comment contains too many characters.'});
      return;
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "user": this.state.user, "body": this.state.body })
    };
    this.setState(
      {user : '', body: '', warning: '' }
      );
    fetch(BACKEND_URL + '/api/comments/', requestOptions)
      .then(() => {
        this.refreshComments();
      });
    

  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };


 refreshComments() {
    fetch(BACKEND_URL + '/api/comments/')
      .then(response => {
        return response.json();
      })
      .then(data => {
          this.setState({
            commentList: data.reverse().slice(0, 3)
          });
      });
  }

  componentDidMount() {
    this.refreshComments();
  } 

  renderItems = () => {
    const { viewCompleted } = this.state;
    const comments = this.state.commentList

    return comments.map((item) => (
      <li
        key={item.id}
        className="list-group-item"
      >
        <div className="">
          <div className="">
            <span
              className={`todo-title mr-2 blockquote`}
              title={item.body}
            >
              "{item.body}"
            </span>
          </div>
        </div>
        <div className="">
          <div className="">
            <div className=" d-flex justify-content-end">
              <span className="text-muted">
                {item.user}
              </span>
            </div>
            <div className=" d-flex justify-content-end">
              <small className="text-muted">
                {new Date(item.timestamp).toDateString()}
              </small>
            </div>
          </div>
        </div>
      </li>
    ));
  };

  render() {
    var characterCountColorClass = 'text-muted'
    if (this.state.body.length > COMMENT_MAXIMUM) {
      characterCountColorClass = 'text-danger'
    }
    return (
      <main className="comment-page">
       {/* <div class="row">
          <div class="col">
          </div>
          <div class="col-md-10 p-4">
            <h1 className="text-rich-black text-center">Leave a message!</h1>
            <div class="p-4 text-center">
              On this page you will find a guestbook application that you can use to leave me a note.
              Serious or silly, focused or fun, feel free to leave a comment and sign my guest book!
            </div>
          </div>

          <div class="col">
          </div>
        </div>*/}
        <div className="comment-container">
          <div class="comment-title-container">
            <span className="display-5">Write A Comment</span>
          </div>
          <div class="form-container">
            <form onSubmit={this.handleSubmit} className="justify-content-center">
              <label>
                <div>
                  <div className="form-group">
                    <label>Your Name</label>
                    <input className="form-control bg-transparent-25 vw-20" type="text" name="user" value={this.state.user} onChange={this.handleChange}/>
                  </div>
                  <div className="form-group">
                    <label>Your Comment</label>
                    <textarea className="form-control bg-transparent-25 vw-20" type="text" name="body" value={this.state.body} onChange={this.handleChange} />
                    <div className="row d-flex justify-content-end mr-1">
                      <small className={characterCountColorClass}>
                        {this.state.body.length}
                      </small>
                    </div>
                  </div>
                  <input className="form-control" type="submit" value="Submit" class="btn btn-dark"/>
                </div>
              </label>
            </form>
          </div>
          <div className="row justify-content-center align-items-start w-100 m-1" style={{height: 5 + "vh"}}>
            <small className="text-danger">
              {this.state.warning}
            </small>
          </div>
        </div>
        <div className="comment-display">
          <div class="comment-title-container">
            <span class="display-5">Recent Comments</span>
          </div>
          <ul className="list-group list-group-flush border-top-0">
            {this.renderItems()}
          </ul>
        </div>
        <div className="comment-right-pane">
        </div>
      </main>
    );
  }
}

export default CommentBox;
